import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';
import { filterChildrenByKey } from '../../utils/HelpFunctions';
import { theme } from '../Theme';
import { LinkEvaluator } from '../../utils/LinkEvaluator';

export const HeroMaxWidth = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 80rem;
  margin: auto;

  &.fullwidth {
    max-width: 100%;
  }

  .content-placer {
    padding: 35px 65px;

    ${theme.below.lg} {
      padding: 20px;
    }
  }

  h1,
  h2 {
  }
`;

const boxTitleStyle = css`
  &.SMALL {
    h1,
    h2 {
      font-size: 36px;
      line-height: 1.3;
    }
  }

  &.MEDIUM {
    h1,
    h2 {
      font-size: 46px;
      line-height: 1.3;
    }
  }

  &.LARGE {
    h1,
    h2 {
      font-size: 66px;
      line-height: 1.2;

      ${theme.below.md} {
        font-size: 50px;
      }
    }
  }

  &.EXTRA_LARGE {
    h1,
    h2 {
      font-size: 76px;
      line-height: 1.2;
    }
  }
`;

export const ImageHero = ({
  fullWidth,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  link,
  desktopTextColor,
  desktopTextShadowColor,
  mobileTextColor,
  mobileTextShadowColor,
  header,
  headerSize,
  subHeading,
  text,
  textAlignment,
  horizontalPosition,
  verticalPosition,
  children
}) => {
  const buttonChildren = filterChildrenByKey(children, 'button', true);

  return (
    <Above breakpoint="lg">
      {matches => (
        <HeroMaxWidth
          className={cx(
            headerSize?.value,
            boxTitleStyle,
            fullWidth?.value && 'fullwidth'
          )}
        >
          <LinkEvaluator link={link}>
            <Image
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={
                matches ? desktopImageRatio?.value : mobileImageRatio?.value
              }
              sizes={fullWidth ? [1] : [1, 1, 1, 1, 1280]}
              cover
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              textColor={matches ? desktopTextColor : mobileTextColor}
              textShadowColor={
                matches ? desktopTextShadowColor : mobileTextShadowColor
              }
              text={text}
              subHeading={subHeading}
              header={header}
              textAlignment={textAlignment}
              buttonChildren={buttonChildren}
              forType={'hero'}
            />
          </LinkEvaluator>
        </HeroMaxWidth>
      )}
    </Above>
  );
};
