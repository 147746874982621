import React, { createElement } from 'react';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';
import { BoxContent } from './BoxContent';
import { FinntackButton } from '../ui/FinntackButton';
import { LinkEvaluator } from '../../utils/LinkEvaluator';

const CollageRowWrapper = styled('div')`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 10vw);
  grid-column-gap: ${props => props.gap};
  grid-row-gap: ${props => props.gap};
  max-width: calc(${theme.maxWidth} + 40px);
  width: 100%;

  &:not(.fullwidth) {
    padding: 0 20px;
  }

  ${theme.above.xl} {
    &:not(.fullwidth) {
      grid-template-rows: repeat(4, 8rem);
    }
  }

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 40vw);
  }

  &.fullwidth {
    max-width: 100%;
  }

  &.items-4 {
    .div-one {
      grid-area: 1 / 1 / 5 / 5;
    }
    .div-two {
      grid-area: 1 / 5 / 3 / 9;
    }
    .div-three {
      grid-area: 3 / 5 / 5 / 7;
    }
    .div-four {
      grid-area: 3 / 7 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 4 / 3;
        &.LARGE,
        &.EXTRA_LARGE {
          h1,
          h2 {
            font-size: 26px;
          }
        }
      }
      .div-three {
        grid-area: 4 / 1 / 5 / 2;
      }
      .div-four {
        grid-area: 4 / 2 / 5 / 3;
      }
    }
  }

  &.items-3 {
    .div-one {
      grid-area: 1 / 1 / 5 / 5;
    }
    .div-two {
      grid-area: 1 / 5 / 3 / 9;
    }
    .div-three {
      grid-area: 3 / 5 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 4 / 3;
        &.LARGE,
        &.EXTRA_LARGE {
          h1,
          h2 {
            font-size: 26px;
          }
        }
      }
      .div-three {
        grid-area: 4 / 1 / 5 / 3;
        &.LARGE,
        &.EXTRA_LARGE {
          h1,
          h2 {
            font-size: 26px;
          }
        }
      }
    }
  }

  &.items-2 {
    .div-one {
      grid-area: 1 / 1 / 5 / 5;
    }
    .div-two {
      grid-area: 1 / 5 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 5 / 3;
      }
    }
  }

  &.items-1 {
    .div-one {
      grid-area: 1 / 1 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 5 / 3;
      }
    }
  }
`;

const boxTitleStyle = css`
  &.SMALL {
    h1,
    h2 {
      font-size: 24px;
      line-height: 1.3;

      ${theme.below.lg} {
        font-size: 20px;
        line-height: 1.1;
      }
    }
  }

  &.MEDIUM {
    h1,
    h2 {
      font-size: 36px;
      line-height: 1.3;
      ${theme.below.lg} {
        line-height: 1.1;
        font-size: 26px;
      }
    }
  }

  &.LARGE {
    h1,
    h2 {
      font-size: 46px;
      line-height: 1.2;
      ${theme.below.lg} {
        line-height: 1.1;
        font-size: 36px;
      }
    }
  }

  &.EXTRA_LARGE {
    h1,
    h2 {
      font-size: 66px;
      line-height: 1.2;
      ${theme.below.lg} {
        font-size: 56px;
        line-height: 1.1;
      }
    }
  }

  ${theme.below.lg} {
    .content-text {
      margin-top: 4px;
      font-size: 14px;
      line-height: 1.3;
    }

    .buttons-row {
      margin-top: 8px;

      .finntack-button-wrapper {
        height: 30px;
        font-size: 11px;
        padding: 0 1.5rem;
      }
    }
  }
`;

const CollageRowItemWrapper = styled('div')`
  .content-placer {
    padding: 1rem;
  }
`;

export const CollageRow = ({ fullWidth, gap, children }) => {
  const childClassName = children?.length;

  // fix for Safari
  const numberToText = number => {
    switch (number) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';

      default:
        return 'number-not-found';
    }
  };

  return (
    <CollageRowWrapper
      gap={gap?.value ? '3px' : '0px'}
      className={cx('items-' + childClassName, fullWidth?.value && 'fullwidth')}
    >
      <>
        {children?.map((child, index) => {
          return (
            <CollageRowItemWrapper
              key={index + 'top-block-item'}
              className={cx(
                boxTitleStyle,
                'div-' + numberToText(index + 1),
                fullWidth?.value && 'fullwidth',
                child?.props?.headerSize?.value
              )}
            >
              <CollageRowItem index={index} {...child?.props}></CollageRowItem>
            </CollageRowItemWrapper>
          );
        })}
      </>
    </CollageRowWrapper>
  );
};

export const CollageRowItem = ({
  blockLink,
  image,
  text,
  textColor,
  textShadowColor,
  header,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition,
  index
}) => {
  const imageSizes = [
    [1, 1, 1, 1, 660],
    [1, 1, 1, 1, 660],
    [1 / 2, 1 / 2, 1 / 2, 1 / 2, 350],
    [1 / 2, 1 / 2, 1 / 2, 1 / 2, 350]
  ];

  const buttonComp = createElement(
    FinntackButton,
    {
      className: `${buttonTheme?.value ?? 'BLACK'}`
    },
    buttonText?.value
  );

  return (
    <LinkEvaluator link={blockLink}>
      <Image
        sizes={imageSizes[index]}
        src={image?.value}
        fillAvailableSpace
        quality={100}
        aspect="1:1"
      >
        <BoxContent
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPosition}
          textColor={textColor}
          textShadowColor={textShadowColor}
          text={text}
          header={header}
          textAlignment={{ value: 'ALIGN_CENTER' }}
          buttonChildren={buttonText?.value ? [buttonComp] : []}
          forType={'hero'}
        />
      </Image>
    </LinkEvaluator>
  );
};
